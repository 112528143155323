// component
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const isAdmin = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'activity logs',
    path: '/dashboard/activity-logs',
    icon: getIcon('eva:activity-fill')
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon('fa:shopping-basket')
  },
  {
    title: 'blogs',
    path: '/dashboard/blogs',
    icon: getIcon('eva:file-text-fill'),
    subItem: [
      {
        item: [
          {
            title: 'Blogs',
            path: '/dashboard/blogs',
            icon: getIcon('eva:file-text-fill')
          },
          {
            title: 'Blogs Categories',
            path: '/dashboard/blogs-category',
            icon: getIcon('eva:file-text-fill')
          }
        ]
      }
    ]
  },
  {
    title: 'stripe settings',
    path: '/dashboard/stripe-setting',
    icon: getIcon('fa:cc-stripe')
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
    subItem: [
      {
        item: [
          {
            title: 'Users',
            path: '/dashboard/users',
            icon: getIcon('eva:file-text-fill')
          },
          {
            title: 'Users Roles',
            path: '/dashboard/user-role',
            icon: getIcon('eva:file-text-fill')
          }
        ]
      }
    ]
  },
  {
    title: 'coupon code',
    path: '/dashboard/coupons',
    icon: getIcon('bxs:coupon')
  },
  {
    title: 'countries',
    path: '/dashboard/countries',
    icon: getIcon('carbon:flag-filled')
  },
  {
    title: 'page',
    path: '/dashboard/pages',
    icon: getIcon('eva:people-fill'),
    subItem: [
      {
        item: [
          {
            title: 'Privacy Policy',
            path: '/dashboard/pages/privacy-policy',
            icon: getIcon('eva:file-text-fill')
          },
          {
            title: 'Refund Policy',
            path: '/dashboard/pages/refund-policy',
            icon: getIcon('eva:file-text-fill')
          }
        ]
      }
    ]
  },
  {
    title: 'menus',
    path: '/dashboard/menus',
    icon: getIcon('bxs:food-menu')
  },
  {
    title: 'home content',
    path: '/dashboard/home-page-content',
    icon: getIcon('fluent:document-one-page-24-filled')
  },
  {
    title: 'footer content',
    path: '/dashboard/footer-content',
    icon: getIcon('fluent:document-footer-16-filled')
  },
  {
    title: 'services',
    icon: getIcon('eos-icons:package'),
    subItem: [
      {
        item: [
          {
            title: 'all services',
            path: '/dashboard/services',
            icon: getIcon('ep:menu')
          },
          {
            title: 'single service content',
            path: '/dashboard/service-content',
            icon: getIcon('ic:round-medical-services')
          }
        ]
      }
    ]
  },
  {
    title: 'packages',
    icon: getIcon('ep:menu'),
    subItem: [
      {
        item: [
          {
            title: 'packages list',
            path: '/dashboard/packages',
            icon: getIcon('fluent:document-one-page-24-filled')
          }
        ]
      }
    ]
  },
  {
    title: 'FAQs',
    path: '/dashboard/pages/faqs',
    icon: getIcon('wpf:faq')
  },
  { title: 'blocklist', path: '/dashboard/blocklist', icon: getIcon('fa:ban') },
  {
    title: 'testimonials',
    path: '/dashboard/reviews',
    icon: getIcon('dashicons:testimonial')
  },
  {
    title: 'Robots.txt',
    path: '/dashboard/robots.txt',
    icon: getIcon('bxs:file-txt')
  }
];
const isQA=[
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon('fa:shopping-basket')
  },
]
const isCSR = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'orders',
    path: '/dashboard/orders',
    icon: getIcon('fa:shopping-basket')
  },
];
const isUser = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'blogs',
    path: '/dashboard/blogs',
    icon: getIcon('eva:file-text-fill'),
    subItem: [
      {
        item: [
          {
            title: 'Blogs',
            path: '/dashboard/blogs',
            icon: getIcon('eva:file-text-fill')
          },
          {
            title: 'Blogs Categories',
            path: '/dashboard/blogs-category',
            icon: getIcon('eva:file-text-fill')
          }
        ]
      }
    ]
  },
  {
    title: 'countries',
    path: '/dashboard/countries',
    icon: getIcon('carbon:flag-filled')
  },
  {
    title: 'page',
    path: '/dashboard/pages',
    icon: getIcon('eva:people-fill'),
    subItem: [
      {
        item: [
          {
            title: 'Privacy Policy',
            path: '/dashboard/pages/privacy-policy',
            icon: getIcon('eva:file-text-fill')
          },
          {
            title: 'Refund Policy',
            path: '/dashboard/pages/refund-policy',
            icon: getIcon('eva:file-text-fill')
          }
        ]
      }
    ]
  },
  {
    title: 'menus',
    path: '/dashboard/menus',
    icon: getIcon('bxs:food-menu')
  },
  {
    title: 'home content',
    path: '/dashboard/home-page-content',
    icon: getIcon('fluent:document-one-page-24-filled')
  },
  {
    title: 'footer content',
    path: '/dashboard/footer-content',
    icon: getIcon('fluent:document-footer-16-filled')
  },
  {
    title: 'services',
    icon: getIcon('eos-icons:package'),
    subItem: [
      {
        item: [
          {
            title: 'all services',
            path: '/dashboard/services',
            icon: getIcon('ep:menu')
          },
          {
            title: 'single service content',
            path: '/dashboard/service-content',
            icon: getIcon('ic:round-medical-services')
          }
        ]
      }
    ]
  },
  {
    title: 'packages',
    icon: getIcon('ep:menu'),
    subItem: [
      {
        item: [
          {
            title: 'packages list',
            path: '/dashboard/packages',
            icon: getIcon('fluent:document-one-page-24-filled')
          }
        ]
      }
    ]
  },
  {
    title: 'FAQs',
    path: '/dashboard/pages/faqs',
    icon: getIcon('wpf:faq')
  },
  {
    title: 'testimonials',
    path: '/dashboard/reviews',
    icon: getIcon('dashicons:testimonial')
  },
  {
    title: 'Robots.txt',
    path: '/dashboard/robots.txt',
    icon: getIcon('bxs:file-txt')
  }
];

export default function sidebarConfig(role) {
  return role == '1' ? isAdmin : role == '4' ? isCSR : role == '5' ? isQA : isUser;
}
